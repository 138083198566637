<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title">
                                    Sale Invoice Summary Report
                                </h4>

                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{
                                        $t('InvoicePrintReport.Home') }}</a></li>
                                    <li class="breadcrumb-item active">
                                        Sale Invoice Summary Report
                                    </li>
                                </ol>
                            </div>
                            <div class="col-auto align-self-center">
                                <a v-on:click="PrintRdlc()" class="btn btn-sm btn-outline-primary mx-1">
                                    <i class="fas fa-print font-14"></i>
                                    {{ $t('Print') }}
                                </a>
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                    class="btn btn-sm btn-outline-danger">
                                    {{ $t('Categories.Close') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="hr-dashed hr-menu mt-0" />
            <div class="row align-items-center">
                <div class="row align-items-end">
                    <div class=" col-lg-4   form-group">
                        <label>Invoice Type</label>
                        <multiselect v-model="invoiceType"
                            :options="['Sale Inovice Post', 'Sale Return']" :show-labels="false"
                            v-bind:placeholder="$t('Select an Option')">
                        </multiselect>
                    </div>
                    <div class=" col-lg-4   form-group" >
                        <label>Compare With:</label>
                        <multiselect v-model="compareWith"
                            :options="['Previous Year(s)', 'Previous Period(s)', 'Previous Quarter(s)', 'Previous Month(s)','Previous Day(s)']"
                            :show-labels="false" v-bind:placeholder="$t('Select an Option')" v-on:input="GetPeriods()">
                        </multiselect>
                    </div>
                    <div class=" col-lg-4 form-group" v-if="allowBranches">
                    <label>{{ $t('Branches.SelectBranch') }}</label>
                    <branch-dropdown v-model="branchIds" :ismultiple="true" :islayout="false" />
                </div>


                    <div class=" col-lg-4   form-group" v-if="isPreviousYear">
                        <label>Number of Year(s)</label>
                        <multiselect v-model="numberOfPeriods" :options="['1', '2', '3', '4', '5']" :show-labels="false"
                            v-bind:placeholder="$t('Select an Option')">
                        </multiselect>
                    </div>
                    <div class=" col-lg-4   form-group" v-if="isPreviousPeriod">
                        <label>Number of Period(s)</label>
                        <multiselect v-model="numberOfPeriods" :options="financialYears" :show-labels="false"
                            v-bind:placeholder="$t('Select an Option')">
                        </multiselect>
                    </div>
                    <div class=" col-lg-4   form-group" v-if="isPreviousQuarter">
                        <label>Number of Quarter(s)</label>
                        <multiselect v-model="numberOfPeriods" :options="['1', '2', '3', '4']" :show-labels="false"
                            v-bind:placeholder="$t('Select an Option')">
                        </multiselect>
                    </div>
                    <div class=" col-lg-4   form-group" v-if="isPreviousMonth">
                        <label>Number of Month(s)</label>
                        <multiselect v-model="numberOfPeriods"
                            :options="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']" :show-labels="false"
                            v-bind:placeholder="$t('Select an Option')">
                        </multiselect>
                    </div>
                    <div class=" col-lg-4   form-group" v-if="isPreviousDays">
                        <label>Number of Days(s)</label>
                        <multiselect v-model="numberOfPeriods"
                            :options="['7 Days', '15 Days', '30 Days']" :show-labels="false"
                            v-bind:placeholder="$t('Select an Option')">
                        </multiselect>
                    </div>
                    <div class=" col-lg-4  form-group pe-0">
                        <button v-if="numberOfPeriods == 0" disabled
                            href="javascript:void(0);" class="btn btn-outline-primary me-2">
                            Apply Filters
                        </button>
                        <button v-else v-on:click="GetSalesList()" href="javascript:void(0);"
                            class="btn btn-outline-primary me-2">
                            Apply Filters
                        </button>
                        <a v-on:click="RemoveFilters()" href="javascript:void(0);" class="btn btn-outline-danger">
                            Clear Filters
                        </a>
                    </div>
                </div>
            </div>
            <hr class="hr-dashed hr-menu mt-0" />
            <div class="row" v-if="showTable">
                <div class="col-md-12">
                    <div id="chart">
                        <apexchart type="line" height="350" :options="chartOptions" :series="series" :key="render"></apexchart>
                    </div>
                </div>
                <div class="card col-md-12">
                    <div class="card-body"></div>
                    <div class="table-responsive"  v-for="(sale, index) in salesList" :key="index">
                        <h6>{{ sale.compareWith }}</h6>
                        <table class="table table-striped table-hover">
                            <thead class="thead-light table-hover">
                                <tr>
                                    <th>#</th>

                                    <th class="text-center">
                                        Total Sales
                                    </th>
                                    <th class="text-center">
                                        Total {{ $t('InvoicePrintReport.GrossValue') }}
                                    </th>
                                    <th class="text-center">
                                        Total {{ $t('InvoicePrintReport.DisountAmount') }}
                                    </th>
                                    <th class="text-center">
                                        Total {{ $t('InvoicePrintReport.NetSaleAmount') }}
                                    </th>
                                    <th class="text-center">
                                        Total {{ $t('InvoicePrintReport.VATAmount') }}
                                    </th>
                                    <th class="text-center">
                                        {{ $t('InvoicePrintReport.TotalAmount') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ index + 1 }}
                                    </td>
                                    <td class="text-center">
                                        {{ sale.totalSales }}
                                    </td>
                                    <td class="text-center">
                                        {{ sale.totalGrossAmount }}
                                    </td>
                                    <td class="text-center">
                                        {{ sale.totalDiscountAmount }}
                                    </td>
                                    <td class="text-center">
                                        {{  
                                            (parseFloat((sale.totalGrossAmount)-(sale.totalDiscountAmount)))
                                            .toFixed(3)
                                            .slice(0, -1)
                                            .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,"$1,")  
                                        }}
                                    </td>
                                    <td class="text-center">
                                        {{ sale.totalVatAmount }}
                                    </td>
                                    <td class="text-center">
                                        {{ sale.totalTotalAmount }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <invoicedetailsprint :show="show" v-if="show" :reportsrc="reportsrc1" :changereport="changereportt"
            @close="show = false" @IsSave="IsSave" />
        <loading :active.sync="loading" :can-cancel="true" :is-full-page="true"></loading>
    </div>
</template>
<script>
import clickMixin from '@/Mixins/clickMixin'
import moment from "moment";
import Multiselect from 'vue-multiselect'
import Loading from 'vue-loading-overlay';
import "vue-loading-overlay/dist/vue-loading.css";

export default {
    mixins: [clickMixin],
    components: {
        Multiselect,
        Loading
    },
    data: function () {
        return {
            allowBranches: false,
            branchIds: [],
            branchId: '',
            financialYears: [],
            compareWith: '',
            reportsrc1: '',
            changereportt: 0,
            show: false,
            isPreviousYear: false,
            isPreviousPeriod: false,
            isPreviousQuarter: false,
            isPreviousMonth: false,
            isPreviousDays: false,
            numberOfPeriods: '',
            loading: false,
            showTable: false,
            invoiceType: '',
            salesList : [],
            render:0,

            series: [],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [5, 7, 5],
                    curve: 'straight',
                    dashArray: [0, 8, 5]
                },
                title: {
                    text: 'Sale Invoices',
                    align: 'left'
                },
                legend: {
                    tooltipHoverFormatter: function (val, opts) {
                        return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                    }
                },
                markers: {
                    size: 0,
                    hover: {
                        sizeOffset: 6
                    }
                },
                xaxis: {
                    categories: [],
                    convertedCatToNumeric : false
                },
                grid: {
                    borderColor: '#f1f1f1',
                }
            },
        }
    },
    watch: {
        
        branchIds: function () {
            var root = this;
            this.branchId = '';
            this.branchIds.forEach(function (result) {
                root.branchId = root.branchId == '' ? result.id : root.branchId + ',' + result.id;
            })
            this.GetSalesList();
        }
    },
    methods: {
        GotoPage: function (link) {
            this.$router.push({ path: link });
        },

        getDate: function (date) {
            return moment(date).format('l');
        },
        AdvanceFilters: function () {
            this.fromDate = moment().format("DD MMM YYYY");
            this.toDate = moment().format("DD MMM YYYY");
            this.showDates = false;
            this.compareWith = "";
            this.numberOfPeriods = "";
        },
        RemoveFilters: function () {
            this.numberOfPeriods = '';
            this.compareWith = '';
            this.customerId = '';
            this.invoiceType = '';
            this.showTable = false;
            this.branchIds ='';
        },
        convertDate: function (date) {
            return moment(date).format("l");
        },
        GetPeriods: function () {
            if (this.compareWith == 'Previous Year(s)') {
                this.financialYears = [];
                this.isPreviousYear = true;
                this.isPreviousPeriod = false;
                this.isPreviousQuarter = false;
                this.isPreviousMonth = false;
                this.isPreviousDays = false;
                this.numberOfPeriods = '';
            }
            if (this.compareWith == 'Previous Period(s)') {
                this.isPreviousYear = false;
                this.isPreviousPeriod = true;
                this.isPreviousQuarter = false;
                this.isPreviousMonth = false;
                this.isPreviousDays = false;
                this.numberOfPeriods = '';
                this.getFinancialYears();
            }
            if (this.compareWith == 'Previous Quarter(s)') {
                this.financialYears = [];
                this.isPreviousYear = false;
                this.isPreviousPeriod = false;
                this.isPreviousQuarter = true;
                this.isPreviousMonth = false;
                this.isPreviousDays = false;
                this.numberOfPeriods = '';
            }
            if (this.compareWith == 'Previous Month(s)') {
                this.financialYears = [];
                this.isPreviousYear = false;
                this.isPreviousPeriod = false;
                this.isPreviousQuarter = false;
                this.isPreviousDays = false;
                this.isPreviousMonth = true;
                this.numberOfPeriods = '';
            }
            if (this.compareWith == 'Previous Day(s)') {
                this.financialYears = [];
                this.isPreviousYear = false;
                this.isPreviousPeriod = false;
                this.isPreviousQuarter = false;
                this.isPreviousMonth = false;
                this.isPreviousDays = true;
                this.numberOfPeriods = '';
            }
        },
        IsSave: function () {
            this.show = !this.show;
        },
        PrintRdlc:function() {
            debugger;
            var companyId = '';
                    if (this.$session.exists()) {
                        companyId = localStorage.getItem('CompanyID');
                    }
                        this.reportsrc1=  this.$ReportServer+'/Invoice/A4_DefaultTempletForm.aspx?invoiceType=' + this.invoiceType + '&numberOfPeriods=' + this.numberOfPeriods + '&compareWith=' + this.compareWith +'&formName=SaleInvoiceSummary'+'&companyId='+companyId 
                        this.changereportt++;
                        this.show = !this.show;
                },
        GetSalesList: function () {
            var root = this;
            var token = '';
            this.loading = true;
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.get('/Report/GetSalesInvoiceSummary?invoiceType=' + this.invoiceType + '&numberOfPeriods=' + this.numberOfPeriods + '&compareWith=' + this.compareWith + '&branchId=' + this.branchId, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {
                        root.showTable = true;
                        root.salesList = response.data;

                        var totalGrossAmount = [];
                        var totalDiscountAmount = [];
                        var totalNetAmount = [];
                        var totalVatAmount = [];
                        var totalAmount = [];
                        root.chartOptions.xaxis.categories = [];
                        root.salesList.forEach(x => {
                            totalGrossAmount.push(x.totalGrossAmount);
                            totalDiscountAmount.push(x.totalDiscountAmount);
                            totalNetAmount.push(x.totalNetTotal);
                            totalVatAmount.push(x.totalVatAmount);
                            totalAmount.push(x.totalTotalAmount);
                            root.chartOptions.xaxis.categories.push(x.apexChartValue);
                        });
                        root.series = [];
                        root.series.push({
                            name: "Gross Amount",
                            data: totalGrossAmount
                        });
                        root.series.push({
                            name: "Discount Amount",
                            data: totalDiscountAmount
                        });
                        root.series.push({
                            name: "Net Amount",
                            data: totalNetAmount
                        });
                        root.series.push({
                            name: "Vat Amount",
                            data: totalVatAmount
                        });
                        root.series.push({
                            name: "Total Amount",
                            data: totalAmount
                        });
                        root.render++;
                    }
                    root.loading = false;
                });
        },
        getFinancialYears: function () {
            var root = this;
            var token = "";
            if (this.$session.exists()) {
                token = localStorage.getItem("token");
            }
            this.$https.get("/Report/GetYearlyPeriodList", { headers: { Authorization: `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {
                        const financialYear = response.data;
                        root.financialYears = [];
                        financialYear.forEach((item) => {
                            root.financialYears.push(item.name);
                        })
                    }
                });
        },
    },
    mounted: function () {
        this.getFinancialYears();
        this.allowBranches = localStorage.getItem('AllowBranches') == 'true' ? true : false;
    }
}
</script>