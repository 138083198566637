<template>
    <div class="row" v-if="isValid('CanViewCustomer')">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title">{{ $t('Customer.Customer') }}</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('Customer.Home') }}</a>
                                    </li>
                                    <li class="breadcrumb-item active">{{ $t('Customer.Customer') }}</li>
                                </ol>
                            </div>
                            <div class="col-auto align-self-center">

                                <a v-if="isValid('CanAddCustomer') && ((rowCount < limitedCustomer) || (limitedCustomer == 0))"
                                    v-on:click="AddCustomer" href="javascript:void(0);"
                                    class="btn btn-sm btn-outline-primary mx-1">
                                    <i class="align-self-center icon-xs ti-plus"></i>
                                    {{ $t('Customer.AddNew') }}
                                </a>
                                <a v-on:click="ImportDataFromXlsx" href="javascript:void(0);"
                                    v-if="isValid('CanImportCustomer') && ((rowCount < limitedCustomer) || (limitedCustomer == 0))"
                                    class="btn btn-sm btn-outline-primary mx-1">
                                    <i class="align-self-center icon-xs ti-plus"></i>
                                    {{ $t('Customer.ImportCustomer') }}
                                </a>
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                    class="btn btn-sm btn-outline-danger">
                                    {{ $t('Customer.Close') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-6" style="padding-top:20px">
                            <div class="input-group">
                                <button class="btn btn-secondary" type="button" id="button-addon1">
                                    <i class="fas fa-search"></i>
                                </button>
                                <input v-model="search" type="text" class="form-control"
                                    :placeholder="$t('Customer.SearchbyBrand')" aria-label="Example text with button addon"
                                    aria-describedby="button-addon1">
                                <a class="btn btn-soft-primary" v-on:click="AdvanceFilterFor" id="button-addon2"
                                    value="Advance Filter">
                                    <i class="fa fa-filter"></i>
                                </a>
                            </div>
                            
                        </div>
                        <div class=" col-lg-4 mt-1" v-if="!advanceFilters">

<button v-on:click="search22(true)" type="button" class="btn btn-outline-primary mt-3">
    {{ $t('Sale.ApplyFilter') }}
</button>
<button v-on:click="clearData(false)" type="button"
    class="btn btn-outline-primary mx-2 mt-3">
    {{ $t('Sale.ClearFilter') }}
</button>
</div>
                    </div>

                    <div class="row " v-if="advanceFilters">

                        <!-- <div class="col-lg-3">
                            <div class="form-group">
                                <label>{{ $t('Sale.FromDate') }}</label>
                                <datepicker v-model="fromDate" v-bind:isDisable="isDisable" v-on:input="GetDate1"
                                    v-bind:key="randerforempty" />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>{{ $t('Sale.ToDate') }}</label>
                                <datepicker v-model="toDate" v-bind:isDisable="isDisable" v-on:input="GetDate1"
                                    v-bind:key="randerforempty" />
                            </div>
                        </div> -->
                        <div class="col-lg-4">
                            <label class="text  font-weight-bolder">{{ $t('Sale.Customer') }}</label>
                            <customerdropdown v-model="customerId" ref="CustomerDropdown" />
                        </div>
                        <div class="col-lg-4">
                            <label>{{ $t('AddCustomer.PaymentTerms') }}</label>
                            <div class="form-group">
                                <multiselect v-model="paymentTerms2" :preselect-first="true"
                                    v-if="($i18n.locale == 'en' || isLeftToRight())" :options="['Cash', 'Credit']"
                                    :show-labels="false" :placeholder="$t('AddCustomer.SelectType')"
                                    >
                                </multiselect>
                                <multiselect v-else v-model="paymentTerms2" :preselect-first="true" :options="['نقد', 'آجل']"
                                    :show-labels="false" v-bind:placeholder="$t('AddCustomer.SelectOption')">
                                </multiselect>
                            </div>
                        </div>
                        <div class=" col-lg-4">
                            <label>{{ $t('AddCustomer.CustomerCategory') }}: </label>

                            <multiselect v-if="($i18n.locale == 'en' || isLeftToRight())" v-model="customerCategory"
                                :options="['Individual', 'Establishment', 'Company', 'Factory', 'Manufacturer', 'Government']"
                                :show-labels="false" :placeholder="$t('AddCustomer.SelectType')">
                            </multiselect>
                            <multiselect v-else v-model="customerCategory"
                                :options="['فرد', 'مؤسسة', 'شركة', 'مصنع', 'الصانع', 'حكومة']" :show-labels="false"
                                v-bind:placeholder="$t('AddCustomer.SelectOption')">
                            </multiselect>

                        </div>
                        <div class="col-lg-4">
                            <label>{{ $t('AddCustomer.CustomerGroup') }}</label>

                            <customergroupdropdown v-model="customerGroupId" :values="customerGroupId"
                                ref="CustomerGroupDropdown" />

                        </div>

                        <div class="col-lg-4 mt-4">
                            <span id="ember694" class="tooltip-container text-dashed-underline ">{{
                                $t('AddCustomer.CustomerType') }} :</span>

                            <div class="form-check form-check-inline">
                                <input v-model="category" name="contact-sub-type" id="a49946497" class=" form-check-input"
                                    type="radio" value="B2B – Business to Business">
                                <label class="form-check-label pl-0" for="a49946497">{{ $t('AddCustomer.Business') }}({{
                                    $t('AddCustomer.B2B') }})</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input v-model="category" name="contact-sub-type" id="a9ff8eb35" class=" form-check-input"
                                    type="radio" value="B2C – Business to Client">
                                <label class="form-check-label pl-0" for="a9ff8eb35">{{ $t('AddCustomer.Individual')
                                }}({{ $t('AddCustomer.B2C') }})</label>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">

                            <button v-on:click="FilterRecord(true)" type="button" class="btn btn-outline-primary mt-3">
                                {{ $t('Sale.ApplyFilter') }}
                            </button>
                            <button v-on:click="FilterRecord(false)" type="button"
                                class="btn btn-outline-primary mx-2 mt-3">
                                {{ $t('Sale.ClearFilter') }}
                            </button>

                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div v-if="isCashCustomer">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item"><a class="nav-link" v-bind:class="{ active: active == false }"
                                    v-on:click="makeActive(false)" data-bs-toggle="tab" href="#link6" role="tab"
                                    aria-selected="true">Cash/Credit</a></li>
                            <li class="nav-item"><a class="nav-link" v-bind:class="{ active: active == true }"
                                    v-on:click="makeActive(true)" data-bs-toggle="tab" href="#link5" role="tab"
                                    aria-selected="false">Walk-In</a></li>
                        </ul>
                    </div>
                    <div class="table-responsive" v-if="active == false">
                        <table class="table mb-0">
                            <thead class="thead-light table-hover">
                                <tr>
                                    <th>#</th>
                                    <th>{{ $t('Customer.Code') }} </th>
                                    <th v-if="english == 'true'">{{ $t('Customer.CustomerName(English)') | englishLanguage
                                    }}
                                    </th>
                                    <th v-if="isOtherLang()">{{ $t('Customer.CustomerName(Arabic)') | arabicLanguage }}</th>
                                    <th v-if="isOtherLang()">{{ $t('Customer.CompanyName') }}</th>
                                    <th>{{ $t('Customer.CRN') }}</th>
                                    <th>{{ $t('Customer.RegDate') }}</th>
                                    <th>{{ $t('Customer.Category') }}</th>
                                    <th class="text-center">{{ $t('Customer.RunningBalance') }}</th>
                                    <th>{{ $t('Customer.PaymentType') }}</th>

                                    <th>{{ $t('Customer.Status') }}</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(cust, index) in customerlist" v-bind:key="cust.id">
                                    <td>
                                        {{ index + 1 }}
                                    </td>
                                    <td v-if="isValid('CanEditCustomer')">
                                        <strong>
                                            <a href="javascript:void(0)" v-on:click="EditCustomer(cust.id)">{{ cust.code
                                            }}</a>
                                        </strong>
                                    </td>
                                    <td v-else>
                                        {{ cust.code }}
                                    </td>
                                    <td v-if="english == 'true'">{{ cust.englishName }}</td>
                                    <td v-if="isOtherLang()">{{ cust.arabicName }}</td>
                                    <td>{{ cust.customerDisplayName }}</td>
                                    <td>{{ cust.commercialRegistrationNo }}</td>
                                    <td>{{ cust.registrationDate }}</td>
                                    <td>{{ cust.category }}</td>
                                    <td class="text-center">{{ cust.openingBalance >= 0 ? 'Dr ' + cust.openingBalance * +1 :
                                        'Cr ' +
                                        cust.openingBalance * (-1) }}</td>
                                    <td>{{ cust.paymentTerms }}</td>
                                    <td>
                                        <span v-if="cust.isActive" class="badge badge-boxed  badge-outline-success">{{
                                            $t('Customer.Active') }}</span>
                                        <span v-else class="badge badge-boxed  badge-outline-danger">{{
                                            $t('Customer.De-Active') }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table-responsive" v-if="active == true">
                        <table class="table mb-0">
                            <thead class="thead-light table-hover">
                                <tr>
                                    <th>#</th>
                                    <th>{{ $t('Customer.Code') }} </th>
                                    <th v-if="english == 'true'">{{ $t('Customer.CustomerName(English)') | englishLanguage
                                    }}
                                    </th>
                                    <th v-if="isOtherLang()">{{ $t('Customer.CustomerName(Arabic)') | arabicLanguage }}</th>
                                    <th v-if="isOtherLang()">{{ $t('Customer.CompanyName') }}</th>
                                    <th>{{ $t('Customer.CRN') }}</th>
                                    <th>{{ $t('Customer.RegDate') }}</th>
                                    <th>{{ $t('Customer.Category') }}</th>
                                    <th class="text-center">{{ $t('Customer.RunningBalance') }}</th>
                                    <th>{{ $t('Customer.PaymentType') }}</th>

                                    <th>{{ $t('Customer.Status') }}</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(cust, index) in customerlist" v-bind:key="cust.id">
                                    <td>
                                        {{ index + 1 }}
                                    </td>
                                    <td v-if="isValid('CanEditCustomer')">
                                        <strong>
                                            <a href="javascript:void(0)" v-on:click="EditCustomer(cust.id)">{{ cust.code
                                            }}</a>
                                        </strong>
                                    </td>
                                    <td v-else>
                                        {{ cust.code }}
                                    </td>
                                    <td v-if="english == 'true'">{{ cust.englishName }}</td>
                                    <td v-if="isOtherLang()">{{ cust.arabicName }}</td>
                                    <td>{{ cust.customerDisplayName }}</td>
                                    <td>{{ cust.commercialRegistrationNo }}</td>
                                    <td>{{ cust.registrationDate }}</td>
                                    <td>{{ cust.category }}</td>
                                    <td class="text-center">{{ cust.openingBalance >= 0 ? 'Dr ' + cust.openingBalance * +1 :
                                        'Cr ' +
                                        cust.openingBalance * (-1) }}</td>
                                    <td>{{ cust.paymentTerms }}</td>
                                    <td>
                                        <span v-if="cust.isActive" class="badge badge-boxed  badge-outline-success">{{
                                            $t('Customer.Active') }}</span>
                                        <span v-else class="badge badge-boxed  badge-outline-danger">{{
                                            $t('Customer.De-Active') }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    <div class="float-start">
                        <span v-if="currentPage === 1 && rowCount === 0"> {{ $t('Pagination.ShowingEntries') }}</span>
                        <span v-else-if="currentPage === 1 && rowCount < 10"> {{ $t('Pagination.Showing') }} {{ currentPage
                        }}
                            {{ $t('Pagination.to') }} {{ rowCount }} {{ $t('Pagination.of') }} {{ rowCount }} {{
                                $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === 1 && rowCount >= 11"> {{ $t('Pagination.Showing') }}
                            {{ currentPage }} {{ $t('Pagination.to') }} {{ currentPage * 10 }} {{ $t('Pagination.of') }}
                            {{ rowCount }} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === 1"> {{ $t('Pagination.Showing') }} {{ currentPage }} {{
                            $t('Pagination.to') }} {{ currentPage * 10 }} of {{ rowCount }} {{ $t('Pagination.entries')
    }}</span>
                        <span v-else-if="currentPage !== 1 && currentPage !== pageCount"> {{ $t('Pagination.Showing') }}
                            {{ (currentPage * 10) - 9 }} {{ $t('Pagination.to') }} {{ currentPage * 10 }} {{
                                $t('Pagination.of') }}
                            {{ rowCount }} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{ (currentPage * 10) -
                            9 }}
                            {{ $t('Pagination.to') }} {{ rowCount }} {{ $t('Pagination.of') }} {{ rowCount }} {{
                                $t('Pagination.entries') }}</span>
                    </div>
                    <div class="float-end">
                        <div class="" v-on:click="getPage()">
                            <b-pagination pills size="sm" v-model="currentPage" :total-rows="rowCount" :per-page="10"
                                :first-text="$t('Table.First')" :prev-text="$t('Table.Previous')"
                                :next-text="$t('Table.Next')" :last-text="$t('Table.Last')"></b-pagination>
                        </div>
                    </div>

                </div>
            </div>


        </div>

    </div>
    <div v-else>
        <acessdenied></acessdenied>
    </div>
</template>
<script>
import clickMixin from '@/Mixins/clickMixin'
import Multiselect from 'vue-multiselect'

export default {
    name: 'Customer',
    mixins: [clickMixin],
    components: {
        Multiselect,


    },
    data: function () {
        return {
            isDisable: false,
            fromDate: '',
            toDate: '',
            randerforempty: 0,
            paymentTerms: '',
            paymentTerms2: '',
            b2b: false,
            b2c: false,
            advanceFilters: false,
            arabic: '',
            isCashCustomer: false,
            multipleAddress: false,
            active: false,

            english: '',
            customerlist: [],
            search: '',
            customer: [],
            loading: true,
            currentPage: 1,
            pageCount: '',
            rowCount: '',
            limitedCustomer: 0,
            customerId: '',
            customerGroupId: '',
            category: '',
            customerCategory: '',
            
        }



    },
    watch: {
        // search: function (val) {
        //     this.GetCustomerData(val, 1, this.active);
        // }
    },
    methods: {
        searchData: function () {
            this.GetCustomerData(this.search, 1, this.fromDate, this.toDate, this.customerType);
        },


        makeActive: function (item) {

            this.active = item;
            this.GetCustomerData(this.search, 1, item);
        },
        // isCashCustomerFunc: function () {

        //     if (this.isCashCustomer) {

        //         this.customerType = 'Individual';
        //         this.paymentTerms = 'Cash';

        //     } else {
        //         this.customerType = '';
        //         this.paymentTerms = 'Credit';
        //     }
        // },
        AdvanceFilterFor: function () {

            this.advanceFilters = !this.advanceFilters;
            if (this.advanceFilters == false) {
                this.FilterRecord(false);
            }

        },
        FilterRecord: function (type) {

            if (type) {
                if (this.fromDate != '') {
                    if (this.toDate == '') {
                        this.$swal({
                            title: 'Error',
                            text: "Please Select To Date ",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        });

                        return;

                    }
                }
                if (this.toDate != '') {
                    if (this.fromDate == '') {
                        this.$swal({
                            title: 'Error',
                            text: "Please Select From Date ",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        });

                        return;

                    }
                }



            } else {
                this.isDisable = false;

                if (this.$refs.userDropdown != null) {
                    this.$refs.userDropdown.EmptyRecord();
                }


                this.fromDate = '';
                this.toDate = '';
                this.search = "";
                this.randerforempty++;
                this.customerId = '';
                this.paymentTerms2='';
                this.customerCategory='';
                this.category='';

                if (this.$refs.CustomerDropdown != undefined) {
                    this.$refs.CustomerDropdown.Remove();
                }


                if (this.$refs.CustomerGroupDropdown != undefined && this.$refs.CustomerGroupDropdown != null) {
                    this.$refs.CustomerGroupDropdown.EmptyRecord();
                }

            }

            this.GetCustomerData(this.search, this.currentPage, this.active, this.fromDate, this.toDate, this.fromTime, this.toTime, this.isCashCustomer, this.customerId, this.customerCategory,this.customerGroupId,this.category,this.paymentTerms,this.paymentTerms2);


        },
        search22: function () {
                this.GetCustomerData(this.search, this.currentPage, this.active, this.fromDate, this.toDate, this.fromTime, this.toTime, this.isCashCustomer, this.customerId, this.customerCategory,this.customerGroupId,this.category,this.paymentTerms2);
            },

            clearData: function () {
                this.search="";
                this.GetCustomerData(this.search, this.currentPage, this.active, this.fromDate, this.toDate, this.fromTime, this.toTime, this.isCashCustomer, this.customerId, this.customerCategory,this.customerGroupId,this.category,this.paymentTerms2);

            },
        GetDate1: function () {

            if (this.fromDate != '' || this.toDate != '') {
                this.isDisableMonth = true;
                this.year = '';
                this.month = '';
                this.monthObj = '';

            } else {
                this.isDisableMonth = false;
            }

        },


        GotoPage: function (link) {
            this.$router.push({ path: link });
        },
        ImportDataFromXlsx: function () {
            var root = this;
            root.$router.push({
                path: '/ImportExportRecords',
                query: { data: 'Customer' }
            })
        },
        AddCustomer: function () {
            this.$router.push('/addCustomer');
        },

        EditCustomer: function (Id) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            var multipleAddress = localStorage.getItem('MultipleAddress') == 'true' ? true : false;

            this.$https.get('/Contact/ContactDetail?Id=' + Id + '&multipleAddress=' + multipleAddress, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    root.customer = response.data
                }
                root.$router.push({
                    path: '/addCustomer',
                    query: {
                        data: root.customer
                    }
                })
            });
        },
        getPage: function () {
            this.GetCustomerData(this.search, this.currentPage, this.active);
        },

        GetCustomerData: function (search, currentPage, isCashCustomer) {
            var root = this;

            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.get('/Contact/ContactList?isCustomer=true' + '&searchTerm=' + search + '&pageNumber=' + currentPage + '&isCashCustomer=' + isCashCustomer +'&CustomerId=' + this.customerId +'&customerGroupId=' + this.customerGroupId + '&customerCategory=' + this.customerCategory + '&category=' + this.category + '&paymentTerms2=' + this.paymentTerms2, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {


                    // root.$store.state.customerlist.push(response.data.contacts)
                    root.customerlist = response.data.results;
                    root.pageCount = response.data.pageCount;
                    root.rowCount = response.data.rowCount;

                }
            });
        },
        RemoveCustomer: function (id) {
            var root = this;
            // working with IE and Chrome both
            this.$swal({
                icon: 'error',
                title: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Are you sure?' : 'هل أنت متأكد؟',
                text: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'You will not be able to recover this!' : 'لن تتمكن من استرداد هذا!',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Yes, delete it!' : 'نعم ، احذفها!',
                closeOnConfirm: false,
                closeOnCancel: false
            }).then(function (result) {
                if (result) {
                    var token = '';
                    if (root.$session.exists()) {
                        token = localStorage.getItem('token');
                    }
                    root.$https
                        .get('/Contact/ContactDelete?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(function (response) {
                            if (response.data.id != '00000000-0000-0000-0000-000000000000') {
                                root.$store.state.customerlist.splice(root.$store.state.customerlist.findIndex(function (i) {
                                    return i.id === response.data;
                                }), 1);
                                root.$swal({
                                    title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Deleted!' : 'تم الحذف!',
                                    text: response.data.isAddUpdate,
                                    type: 'success',
                                    confirmButtonClass: "btn btn-success",
                                    buttonsStyling: false
                                });
                            } else {
                                root.$swal({
                                    title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                    text: response.data.isAddUpdate,
                                    type: 'error',
                                    confirmButtonClass: "btn btn-danger",
                                    buttonsStyling: false
                                });
                            }
                        },
                            function () {
                                root.loading = false;
                                root.$swal({
                                    title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                    text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                                    type: 'error',
                                    confirmButtonClass: "btn btn-danger",
                                    buttonsStyling: false
                                });
                            });
                }
                else {
                    this.$swal((this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Cancelled!' : 'ألغيت!', (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Your file is still intact!' : 'ملفك لا يزال سليما!', (this.$i18n.locale == 'en' || root.isLeftToRight()) ? 'info' : 'معلومات');
                }
            });
        },
    },
    created: function () {

        this.$emit('input', this.$route.name);
    },
    mounted: function () {
        this.english = localStorage.getItem('English');
        this.arabic = localStorage.getItem('Arabic');
        this.limitedCustomer = parseInt(localStorage.getItem('LimitedCustomer'));
        if (isNaN(this.limitedCustomer)) {
            this.limitedCustomer = 0
        }


        this.isCashCustomer = localStorage.getItem('CashCustomer') == 'true' ? true : false;
        this.multipleAddress = localStorage.getItem('MultipleAddress') == 'true' ? true : false;



        this.GetCustomerData(this.search, 1, this.active);


    }
}
</script>