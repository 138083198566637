<template>
    <div class="row" v-if="isValid('CanViewAutoTemplate')">

        <div class="col-lg-12 col-sm-12">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title">{{ $t('AutoPurchaseTemplate.AutoPurchaseTemplate') }}</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{
                                        $t('AutoPurchaseTemplate.Home') }}</a></li>
                                    <li class="breadcrumb-item active">{{
                                        $t('AutoPurchaseTemplate.AutoPurchaseTemplate')
                                    }}</li>
                                </ol>
                            </div>
                            <div class="col-auto align-self-center">
                                <a v-if="isValid('CanAddAutoTemplate')" v-on:click="AddPurchaseOrder"
                                    href="javascript:void(0);" class="btn btn-sm btn-outline-primary mx-1">
                                    <i class="align-self-center icon-xs ti-plus"></i>
                                    {{ $t('AutoPurchaseTemplate.AddNew') }}
                                </a>
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                    class="btn btn-sm btn-outline-danger">
                                    {{ $t('AutoPurchaseTemplate.Close') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col-md-8 col-sm-12 " style="padding-top:20px">
                            <div class="input-group">
                                <button class="btn btn-secondary" type="button" id="button-addon1">
                                    <i class="fas fa-search"></i>
                                </button>
                                <input v-model="search" type="text" class="form-control"
                                    :placeholder="$t('AutoPurchaseTemplate.Search')"
                                    aria-label="Example text with button addon"
                                    aria-describedby="button-addon1, button-addon2">
                                <a class="btn btn-soft-primary" v-on:click="AdvanceFilterFor" id="button-addon2"
                                    value="Advance Filter">
                                    <i class="fa fa-filter"></i>
                                </a>
                            </div>
                        </div>
                        <div class=" col-lg-4 mt-1" v-if="!advanceFilters">

                            <button v-on:click="search22(true)" type="button" class="btn btn-outline-primary mt-3">
                                {{ $t('Sale.ApplyFilter') }}
                            </button>
                            <button v-on:click="clearData(false)" type="button" class="btn btn-outline-primary mx-2 mt-3">
                                {{ $t('Sale.ClearFilter') }}
                            </button>

                        </div>
                    </div>


                    <div class="row " v-if="advanceFilters">
                        <div class="col-md-3 col-sm-12">
                            <div class="form-group">
                                <label>{{ $t('AutoPurchaseTemplate.FromDate') }}</label>
                                <datepicker v-model="fromDate" />
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-12">
                            <div class="form-group">
                                <label>{{ $t('AutoPurchaseTemplate.ToDate') }}</label>
                                <datepicker v-model="toDate" />
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">

                            <button v-on:click="FilterRecord(true)" type="button" class="btn btn-outline-primary mt-3">
                                {{ $t('Sale.ApplyFilter') }}
                            </button>
                            <button v-on:click="FilterRecord(false)" type="button"
                                class="btn btn-outline-primary mx-2 mt-3">
                                {{ $t('Sale.ClearFilter') }}
                            </button>

                        </div>
                    </div>

                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">

                            <div>
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead class="thead-light table-hover">
                                            <tr>
                                                <th>#</th>
                                                <th>
                                                    {{ $t('AutoPurchaseTemplate.AutoPurchaseTemplateNo') }}
                                                </th>
                                                <th>
                                                    {{ $t('AutoPurchaseTemplate.CreatedDate') }}
                                                </th>
                                                <th v-if="english == 'true'">
                                                    {{ $t('AutoPurchaseTemplate.SupplierName') }}
                                                </th>
                                                <th v-if="isOtherLang()">
                                                    {{ $t('AutoPurchaseTemplate.SupplierNameAr') }}
                                                </th>
                                                <th class="text-center">
                                                    {{ $t('AutoPurchaseTemplate.ReOrderDay') }}
                                                </th>
                                                <th>
                                                    {{ $t('AutoPurchaseTemplate.NetAmount') }}
                                                </th>
                                                <th>
                                                    {{ $t('AutoPurchaseTemplate.Status') }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(purchaseOrder, index) in purchaseOrderList"
                                                v-bind:key="purchaseOrder.id">
                                                <td v-if="currentPage === 1">
                                                    {{ index + 1 }}
                                                </td>
                                                <td v-else>
                                                    {{ ((currentPage * 10) - 10) + (index + 1) }}
                                                </td>
                                                <td v-if="isValid('CanEditAutoTemplate')">
                                                    <strong>
                                                        <a href="javascript:void(0)"
                                                            v-on:click="EditPurchaseOrder(purchaseOrder.id)">{{
                                                                purchaseOrder.registrationNumber }}</a>
                                                    </strong>
                                                </td>
                                                <td v-else>
                                                    {{ purchaseOrder.registrationNumber }}
                                                </td>
                                                <td>
                                                    {{ purchaseOrder.date }}
                                                </td>
                                                <td v-if="english == 'true'">
                                                    {{ purchaseOrder.supplierName }}
                                                </td>
                                                <td v-if="isOtherLang()">
                                                    {{ purchaseOrder.supplierNameArabic }}
                                                </td>
                                                <td class="text-center">
                                                    {{ purchaseOrder.day }}
                                                </td>
                                                <td>
                                                    {{ currency }}
                                                    {{ parseFloat(purchaseOrder.netAmount).toFixed(3).slice(0,
                                                        -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                            "$1,")
                                                    }}
                                                </td>
                                                <td>
                                                    <span class="small badge badge-outline-success"
                                                        v-if="!purchaseOrder.isActive">{{ $t('Close') }}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                                <hr />
                                <div class="row">
                                    <div class="col-lg-6">
                                        <span v-if="currentPage === 1 && rowCount === 0">
                                            {{ $t('Pagination.ShowingEntries') }}
                                        </span>
                                        <span v-else-if="currentPage === 1 && rowCount < 10">
                                            {{ $t('Pagination.Showing') }}
                                            {{ currentPage }} {{ $t('Pagination.to') }}
                                            {{ rowCount }}
                                            {{ $t('Pagination.of') }}
                                            {{ rowCount }}
                                            {{ $t('Pagination.entries') }}
                                        </span>
                                        <span v-else-if="currentPage === 1 && rowCount >= 11">
                                            {{ $t('Pagination.Showing') }}
                                            {{ currentPage }}
                                            {{ $t('Pagination.to') }}
                                            {{ currentPage * 10 }}
                                            {{ $t('Pagination.of') }}
                                            {{ rowCount }}
                                            {{ $t('Pagination.entries') }}
                                        </span>
                                        <span v-else-if="currentPage === 1">
                                            {{ $t('Pagination.Showing') }}
                                            {{ currentPage }}
                                            {{ $t('Pagination.to') }}
                                            {{ currentPage * 10 }} of {{ rowCount }}
                                            {{ $t('Pagination.entries') }}
                                        </span>
                                        <span v-else-if="currentPage !== 1 && currentPage !== pageCount">
                                            {{ $t('Pagination.Showing') }}
                                            {{ (currentPage * 10) - 9 }}
                                            {{ $t('Pagination.to') }}
                                            {{ currentPage * 10 }}
                                            {{ $t('Pagination.of') }}
                                            {{ rowCount }}
                                            {{ $t('Pagination.entries') }}
                                        </span>
                                        <span v-else-if="currentPage === pageCount">
                                            {{ $t('Pagination.Showing') }}
                                            {{ (currentPage * 10) - 9 }}
                                            {{ $t('Pagination.to') }}
                                            {{ rowCount }}
                                            {{ $t('Pagination.of') }}
                                            {{ rowCount }}
                                            {{ $t('Pagination.entries') }}
                                        </span>
                                    </div>
                                    <div class=" col-lg-6">
                                        <div class="float-end" v-on:click="getPage()">
                                            <b-pagination pills size="sm" v-model="currentPage" :total-rows="rowCount"
                                                :per-page="10" :first-text="$t('Table.First')"
                                                :prev-text="$t('Table.Previous')" :next-text="$t('Table.Next')"
                                                :last-text="$t('Table.Last')"></b-pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <purchaseorder-payment :totalAmount="totalAmount" :customerAccountId="customerAccountId" :show="payment"
                v-if="payment" @close="paymentSave" :isPurchase="'true'" :isSaleOrder="'false'"
                :purchaseOrderId="purchaseId" :formName="'AdvancePay'" />
        </div>
    </div>
    <div v-else>
        <acessdenied></acessdenied>
    </div>
</template>

<script>
import clickMixin from '@/Mixins/clickMixin'
import moment from "moment";
export default {
    mixins: [clickMixin],
    data: function () {
        return {
            advanceFilters: false,
            versionAllow: '',
            internationalPurchase: '',
            fromDate: '',
            toDate: '',
            arabic: '',
            english: '',
            search: '',
            searchQuery: '',
            purchaseOrderList: [],
            currentPage: 1,
            pageCount: '',
            rowCount: '',
            currency: '',
            purchaseId: '',
            totalAmount: 0,
            customerAccountId: '',
            payment: false,
            isMultiUnit: false,


            selected: [],
            selectAll: false,
            updateApprovalStatus: {
                id: '',
                approvalStatus: ''
            },
            counter: 0,
            randerList: 0,
        }
    },
    watch: {
        // search: function (val) {
        //     this.getData(val, 1, this.fromDate, this.toDate);
        // },
        fromDate: function (fromDate) {
            this.counter++;
            if (this.counter != 1) {
                localStorage.setItem('fromDate', fromDate);
                this.getData(this.search, this.currentPage, fromDate, this.toDate);
            }
        },
        toDate: function (toDate) {
            this.counter++;
            if (this.counter != 2) {
                localStorage.setItem('toDate', toDate);
                this.getData(this.search, this.currentPage, this.fromDate, toDate);
            }
        }
    },
    methods: {
        search22: function () {
            this.getData(this.search, this.currentPage,  this.fromDate, this.toDate);
        },

        clearData: function () {
            this.search = "";
            this.getData(this.search, this.currentPage,  this.fromDate, this.toDate);

        },




        AdvanceFilterFor: function () {

            this.advanceFilters = !this.advanceFilters;
            if (this.advanceFilters == false) {
                this.FilterRecord(false);
            }
        },
        FilterRecord: function (type) {

            if (type) {
                // if (this.fromDate != '') {
                //     if (this.toDate == '') {
                //         this.$swal({
                //             title: 'Error',
                //             text: "Please Select To Date ",
                //             type: 'error',
                //             icon: 'error',
                //             showConfirmButton: false,
                //             timer: 2000,
                //             timerProgressBar: true,
                //         });

                //         return;

                //     }
                // }
                // if (this.toDate != '') {
                //     if (this.fromDate == '') {
                //         this.$swal({
                //             title: 'Error',
                //             text: "Please Select From Date ",
                //             type: 'error',
                //             icon: 'error',
                //             showConfirmButton: false,
                //             timer: 2000,
                //             timerProgressBar: true,
                //         });

                //         return;

                //     }
                // }



            } else {

                this.fromDate = '';
                this.toDate = '';


            }

            this.getData(this.search, this.currentPage, this.active, this.fromDate, this.toDate);

        },

        GotoPage: function (link) {
            this.$router.push({ path: link });
        },
        //changeStatus: function (id) {
        //    var root = this;
        //    var token = '';
        //    if (this.$session.exists()) {
        //        token = localStorage.getItem('token');
        //    }

        //    var purchase = { id: id, isClose: true };
        //    this.$https.post('/Purchase/SavePurchaseOrderInformation', purchase, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
        //        if (response.data.isSuccess == true) {
        //            root.$swal({
        //                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : '!تم الحفظ',
        //                text: "Purchase Order Closed Successfully!",
        //                type: 'success',
        //                icon: 'success',
        //                showConfirmButton: true
        //            });
        //            root.getPage();
        //        }
        //    });
        //},
        paymentSave: function () {
            this.payment = false;
        },
        paymentModel: function (purchaseId, totalAmount, customerAccountId) {

            this.purchaseId = purchaseId;
            this.totalAmount = totalAmount;
            this.customerAccountId = customerAccountId;
            this.payment = true;
        },
        //DeleteFile: function () {
        //    var root = this;
        //    var token = '';
        //    if (this.$session.exists()) {
        //        token = localStorage.getItem('token');
        //    }
        //    this.$https.get('/Purchase/DeletePo', this.selected, { headers: { "Authorization": `Bearer ${token}` } })
        //        .then(function (response) {
        //            if (response.data != null) {
        //                if (response.data.message.id != '00000000-0000-0000-0000-000000000000') {
        //                    root.$swal({
        //                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Deleted!' : 'تم الحذف!',
        //                        text: response.data.message.isAddUpdate,
        //                        type: 'success',
        //                        confirmButtonClass: "btn btn-success",
        //                        buttonsStyling: false
        //                    }).then(function (result) {
        //                        if (result) {
        //                            root.$router.push('/purchase');
        //                        }
        //                    });
        //                } else {
        //                    root.$swal({
        //                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
        //                        text: response.data.message.isAddUpdate,
        //                        type: 'error',
        //                        confirmButtonClass: "btn btn-danger",
        //                        buttonsStyling: false
        //                    });
        //                }
        //            }
        //        },
        //            function () {

        //                root.$swal({
        //                    title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
        //                    text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Update UnSuccessfully' : 'التحديث غير ناجح',
        //                    type: 'error',
        //                    confirmButtonClass: "btn btn-danger",
        //                    buttonsStyling: false
        //                });
        //            });
        //},
        select: function () {
            this.selected = [];
            if (!this.selectAll) {
                for (let i in this.purchaseOrderList) {
                    this.selected.push(this.purchaseOrderList[i].id);
                }
            }
        },
        getPage: function () {
            this.getData(this.search, this.currentPage, this.fromDate, this.toDate);
        },

        makeActive: function () {
            this.selectAll = false;
            this.selected = [];
            this.getData(this.search, 1, this.fromDate, this.toDate);
        },
        getData: function (search, currentPage, fromDate, toDate) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            var branchId = localStorage.getItem('BranchId');
            localStorage.setItem('currentPage', this.currentPage);
            this.$https.get('/Purchase/PurchaseTemplateList?searchTerm=' + search + '&pageNumber=' + currentPage + '&fromDate=' + fromDate + '&toDate=' + toDate + '&branchId=' + branchId, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {
                        root.$store.dispatch('GetPurchaseOrderList', response.data.results);
                        root.purchaseOrderList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                        root.currentPage = currentPage;
                        root.randerList++;
                    }
                });
        },
        //RemovePurchaseOrder: function (id) {
        //    var root = this;
        //    // working with IE and Chrome both
        //    this.$swal({
        //        title: "Are you sure?",
        //        text: "You will not be able to recover this!",
        //        type: "warning",
        //        showCancelButton: true,
        //        confirmButtonColor: "#DD6B55",
        //        confirmButtonText: "Yes, delete it!",
        //        closeOnConfirm: false,
        //        closeOnCancel: false
        //    }).then(function (result) {
        //        if (result) {

        //            var token = '';
        //            if (root.$session.exists()) {
        //                token = localStorage.getItem('token');
        //            }
        //            root.$https.get('/Purchase/DeletePurchaseOrder?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
        //                .then(function (response) {
        //                    if (response.data.message.id != '00000000-0000-0000-0000-000000000000') {


        //                        root.$store.state.purchaseOrderList.splice(root.$store.state.purchaseOrderList.findIndex(function (i) {
        //                            return i.id === response.data.message.id;
        //                        }), 1);
        //                        root.$swal({
        //                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Deleted!' : 'تم الحذف!',
        //                            text: response.data.message.isAddUpdate,
        //                            type: 'success',
        //                            confirmButtonClass: "btn btn-success",
        //                            buttonsStyling: false
        //                        });
        //                    } else {
        //                        root.$swal({
        //                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
        //                            text: response.data.message.isAddUpdate,
        //                            type: 'error',
        //                            confirmButtonClass: "btn btn-danger",
        //                            buttonsStyling: false
        //                        });
        //                    }
        //                },
        //                    function () {

        //                        root.$swal({
        //                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
        //                            text: "Delete UnSuccessfully",
        //                            type: 'error',
        //                            confirmButtonClass: "btn btn-danger",
        //                            buttonsStyling: false
        //                        });
        //                    });
        //        }
        //        else {
        //            this.$swal('Cancelled', 'Your file is still intact', 'info');
        //        }
        //    });
        //},
        AddPurchaseOrder: function () {

            this.$router.push('/AddAutoPurchaseTemplate');
        },
        //ViewPurchaseOrder: function (id) {
        //    var root = this;
        //    var token = '';
        //    if (this.$session.exists()) {
        //        token = localStorage.getItem('token');
        //    }
        //    root.$https.get('/Purchase/PurchaseOrderDetail?Id=' + id + '&isPoView=' + true + '&isMultiUnit=' + this.isMultiUnit, { headers: { "Authorization": `Bearer ${token}` } })
        //        .then(function (response) {
        //            if (response.data != null) {
        //                root.$router.push({
        //                    path: '/PurchaseOrderView',
        //                    query: { data: response.data }
        //                })
        //            }
        //        },
        //            function (error) {
        //                root.loading = false;
        //                console.log(error);
        //            });
        //},
        EditPurchaseOrder: function (id) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https.get('/Purchase/PurchaseTemplateDetail?Id=' + id + '&isMultiUnit=' + this.isMultiUnit, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {
                        root.$router.push({
                            path: '/AddAutoPurchaseTemplate',
                            query: { data: response.data }
                        })
                    }
                },
                    function (error) {
                        root.loading = false;
                        console.log(error);
                    });
        },


    },
    created: function () {

        if (this.$route.query.data == 'purchaseorders') {
            this.$emit('input', 'purchaseorders');

        }
        else {
            this.$emit('input', this.$route.name);

        }

        if (localStorage.getItem('fromDate') != null && localStorage.getItem('fromDate') != '' && localStorage.getItem('fromDate') != undefined) {
            this.fromDate = localStorage.getItem('fromDate');

        }
        else {
            this.fromDate = moment().startOf('year').format("DD MMM YYYY");

        }
        if (localStorage.getItem('toDate') != null && localStorage.getItem('toDate') != '' && localStorage.getItem('toDate') != undefined) {
            this.toDate = localStorage.getItem('toDate');

        }
        else {
            this.toDate = moment().endOf('year').format("DD MMM YYYY");
        }
    },
    mounted: function () {
        this.english = localStorage.getItem('English');
        this.arabic = localStorage.getItem('Arabic');
        this.internationalPurchase = localStorage.getItem('InternationalPurchase');
        this.isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
        this.versionAllow = localStorage.getItem('VersionAllow');
        if (localStorage.getItem('currentPage') != null && localStorage.getItem('currentPage') != '' && localStorage.getItem('currentPage') != undefined) {
            this.currentPage = parseInt(localStorage.getItem('currentPage'));
            this.makeActive();
        }
        else {
            this.makeActive();

        }
        this.currency = localStorage.getItem('currency');
        //this.getData(this.search, 1);
    },
    updated: function () {
        if (this.selected.length < this.purchaseOrderList.length) {
            this.selectAll = false;
        } else if (this.selected.length == this.purchaseOrderList.length) {
            if (this.selected.length == 0) {
                this.selectAll = false;
            }
            else {
                this.selectAll = true
            }
        }
    }
}
</script>