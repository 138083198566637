<template>
    <div class="row">
        <div class="col-lg-12 px-3">
            <div class="row">
                <div class="col-sm-6">
                    <div class="col d-flex align-items-baseline">
                        <div class="media">
                            <span class="circle-singleline" style="background-color: #1761FD !important;">IV</span>
                            <div class="media-body align-self-center ms-3">
                                <h6 class="m-0 font-20">{{ $t('Item View Setup') }} </h6>
                                <div class="col d-flex ">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>

            <hr class="hr-dashed hr-menu mt-0" />
            <div class="row ms-5">
                <div class="col-lg-6">
                    <h5 class="fw-bold">Invoice Display Name</h5>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="checkbox form-check-inline mx-2 mt-2">
                                <input v-model="itemViewSetup.isCode" v-on:change="GetItemDisplayValue('Item Code')"
                                    type="checkbox" id="inlineCheckbox12001">
                                <label for="inlineCheckbox12001">{{ $t('Item Code') }}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="checkbox form-check-inline mx-2 mt-2">
                                <input v-model="itemViewSetup.isNameEn"
                                    v-on:change="GetItemDisplayValue('Item Name (English)')" type="checkbox"
                                    id="inlineCheckbox12002">
                                <label for="inlineCheckbox12002">{{ $t('Item Name (English)') }}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="checkbox form-check-inline mx-2 mt-2">
                                <input v-model="itemViewSetup.isNameAr"
                                    v-on:change="GetItemDisplayValue('Item Name (Arabic)')" type="checkbox"
                                    id="inlineCheckbox12003">
                                <label for="inlineCheckbox12003">{{ $t('Item Name (Arabic)') }}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="checkbox form-check-inline mx-2 mt-2">
                                <input v-model="itemViewSetup.isDescription"
                                    v-on:change="GetItemDisplayValue('Item Description')" type="checkbox"
                                    id="inlineCheckbox12004">
                                <label for="inlineCheckbox12004">{{ $t('Item Description') }}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="checkbox form-check-inline mx-2 mt-2">
                                <input v-model="itemViewSetup.isCategory" v-on:change="GetItemDisplayValue('Item Category')"
                                    type="checkbox" id="inlineCheckbox12005">
                                <label for="inlineCheckbox12005">{{ $t('Item Category') }}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="checkbox form-check-inline mx-2 mt-2">
                                <input v-model="itemViewSetup.isBarcode" v-on:change="GetItemDisplayValue('Item Barcode')"
                                    type="checkbox" id="inlineCheckbox12006">
                                <label for="inlineCheckbox12006">{{ $t('Item Barcode') }}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="checkbox form-check-inline mx-2 mt-2">
                                <input v-model="itemViewSetup.isStyle" v-on:change="GetItemDisplayValue('Item Style/Model')"
                                    type="checkbox" id="inlineCheckbox12007">
                                <label for="inlineCheckbox12007">{{ $t('Item Style/Model') }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <h5 class="fw-bold">Invoice Print Display Name</h5>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="checkbox form-check-inline mx-2 mt-2">
                                <input v-model="itemViewSetupPrint.isCode" v-on:change="GetItemDisplayValueForPrint('Item Code')"
                                    type="checkbox" id="inlineCheckbox120011">
                                <label for="inlineCheckbox120011">{{ $t('Item Code') }}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="checkbox form-check-inline mx-2 mt-2">
                                <input v-model="itemViewSetupPrint.isNameEn"
                                    v-on:change="GetItemDisplayValueForPrint('Item Name (English)')" type="checkbox"
                                    id="inlineCheckbox120022">
                                <label for="inlineCheckbox120022">{{ $t('Item Name (English)') }}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="checkbox form-check-inline mx-2 mt-2">
                                <input v-model="itemViewSetupPrint.isNameAr"
                                    v-on:change="GetItemDisplayValueForPrint('Item Name (Arabic)')" type="checkbox"
                                    id="inlineCheckbox120033">
                                <label for="inlineCheckbox120033">{{ $t('Item Name (Arabic)') }}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="checkbox form-check-inline mx-2 mt-2">
                                <input v-model="itemViewSetupPrint.isDescription"
                                    v-on:change="GetItemDisplayValueForPrint('Item Description')" type="checkbox"
                                    id="inlineCheckbox120044">
                                <label for="inlineCheckbox120044">{{ $t('Item Description') }}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="checkbox form-check-inline mx-2 mt-2">
                                <input v-model="itemViewSetupPrint.isCategory" v-on:change="GetItemDisplayValueForPrint('Item Category')"
                                    type="checkbox" id="inlineCheckbox120055">
                                <label for="inlineCheckbox120055">{{ $t('Item Category') }}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="checkbox form-check-inline mx-2 mt-2">
                                <input v-model="itemViewSetupPrint.isBarcode" v-on:change="GetItemDisplayValueForPrint('Item Barcode')"
                                    type="checkbox" id="inlineCheckbox120066">
                                <label for="inlineCheckbox120066">{{ $t('Item Barcode') }}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="checkbox form-check-inline mx-2 mt-2">
                                <input v-model="itemViewSetupPrint.isStyle" v-on:change="GetItemDisplayValueForPrint('Item Style/Model')"
                                    type="checkbox" id="inlineCheckbox120077">
                                <label for="inlineCheckbox120077">{{ $t('Item Style/Model') }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="hr-dashed hr-menu mt-0" />


            <div class="row mb-5 ms-4 ">
                <div class="col-md-6">
                    <h5 class="fw-bold">Item Display Order</h5>
                    <p><b> Please Drag and Drop Items to Update the Item Display Name.</b></p>
                    <h6>Item Display Name will be</h6>
                    <h6><b>{{ displayName }}</b></h6>
                    <draggable class="col-sm-12 border  py-2" v-model="resultData" style="margin: unset;">
                        <div class="row align-items-baseline p-0 m-0 mb-1" style="background:#f0f8ff"
                            v-for="(module) in resultData" v-bind:key="module.name">
                            <div>
                                <h5>
                                    <a href="javascript:void(0)">
                                        {{ module.name }}
                                    </a>
                                </h5>
                            </div>
                        </div>
                    </draggable>
                </div>
                <div class="col-md-6">
                    <h5 class="fw-bold">Item Printing Display Order</h5>
                    <p><b> Please Drag and Drop Items to Update the Item Display Name for Print.</b></p>
                    <h6>Item Display Name will be</h6>
                    <h6><b>{{ displayNameForPrint }}</b></h6>
                    <draggable class="col-sm-12 border  py-2" v-model="resultData1" style="margin: unset;">
                        <div class="row align-items-baseline p-0 m-0 mb-1" style="background:#f0f8ff"
                            v-for="(module) in resultData1" v-bind:key="module.name">
                            <div>
                                <h5>
                                    <a href="javascript:void(0)">
                                        {{ module.name }}
                                    </a>
                                </h5>
                            </div>
                        </div>
                    </draggable>
                </div>
            </div>
            <div class="form-group col-md-6 mb-5">
                <div class="checkbox form-check-inline mx-2" >
                   <input type="checkbox" id="inlineCheckbox7" v-model="isForAllProduct">
                   <label for="inlineCheckbox7">{{ $t('Do you want to apply this setup on all Products') }} </label>
                </div>
            </div>
        </div>

        <div v-if="!loading" class=" col-lg-12 invoice-btn-fixed-bottom">
            <div class="row">
                <div v-if="!loading" class="col-md-12">
                    <div class="button-items">
                        <button class="btn btn-outline-primary" v-on:click="SaveItemViewSetup()"><i class="far fa-save "></i> {{ $t('AddCustomer.btnSave')
                        }}</button>
                        <button class="btn btn-danger" v-on:click="GotoPage('/StartScreen')">{{ $t('AddCustomer.Cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import draggable from 'vuedraggable';

export default {
    components: {
        Loading,
        draggable
    },
    data: function () {
        return {
            loading: false,
            itemViewSetup: {
                isCode: false,
                isNameEn: false,
                isNameAr: false,
                isDescription: false,
                isCategory: false,
                isBarcode: false,
                isStyle: false,
            },
            itemViewSetupPrint: {
                isCode: false,
                isNameEn: false,
                isNameAr: false,
                isDescription: false,
                isCategory: false,
                isBarcode: false,
                isStyle: false,
            },
            isForAllProduct: false,
            itemViewSetupList: [],
            itemViewSetupListForPrint: [],
            itemViews: {
                itemViewSetupList : [],
                itemViewSetupListForPrint : []
            },
            displayName: '',
            displayNameForPrint: '',
        }
    },

    filters: {},
    validations: {},
    computed: {
        resultData: {
            get() {
                return this.itemViewSetupList;
            },

            set(val) {
                this.itemViewSetupList = val;
                var count = 1;
                this.itemViewSetupList.forEach(function (x) {
                    x.sequence = count
                    count++
                });
                this.updateDisplayName();
            }
        },
        resultData1: {
            get() {
                return this.itemViewSetupListForPrint;
            },

            set(val) {
                this.itemViewSetupListForPrint = val;
                var count = 1;
                this.itemViewSetupListForPrint.forEach(function (x) {
                    x.sequence = count
                    count++
                });
                this.updateDisplayName();
            }
        }
    },
    methods: {
        GotoPage: function (link) {
                this.$router.push({ path: link });
            },
        GetItemDisplayValue: function (value) {
            

            if (value == "Item Code") {
                if (this.itemViewSetup.isCode) {
                    this.itemViewSetupList.push({
                        id: '00000000-0000-0000-0000-000000000000',
                        name: value,
                        displayName: 'PR-00001',
                        sequence: 8
                    });
                    this.itemViewSetupList = this.itemViewSetupList.map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                   
                }
                else {
                    this.itemViewSetupList = this.itemViewSetupList.filter(item => item.name !== value).map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
            }
            if (value == "Item Name (English)") {
                if (this.itemViewSetup.isNameEn) {
                    this.itemViewSetupList.push({
                        id: '00000000-0000-0000-0000-000000000000',
                        name: value,
                        displayName: 'Shoes',
                        sequence: 8
                    });
                    this.itemViewSetupList = this.itemViewSetupList.map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
                else {
                    this.itemViewSetupList = this.itemViewSetupList.filter(item => item.name !== value).map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
            }
            if (value == "Item Name (Arabic)") {
                if (this.itemViewSetup.isNameAr) {
                    this.itemViewSetupList.push({
                        id: '00000000-0000-0000-0000-000000000000',
                        name: value,
                        displayName: 'أحذية',
                        sequence: 8
                    });
                    this.itemViewSetupList = this.itemViewSetupList.map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
                else {
                    this.itemViewSetupList = this.itemViewSetupList.filter(item => item.name !== value).map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
            }
            if (value == "Item Description") {
                if (this.itemViewSetup.isDescription) {
                    this.itemViewSetupList.push({
                        id: '00000000-0000-0000-0000-000000000000',
                        name: value,
                        displayName: 'These are Shoes',
                        sequence: 8
                    });
                    this.itemViewSetupList = this.itemViewSetupList.map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
                else {
                    this.itemViewSetupList = this.itemViewSetupList.filter(item => item.name !== value).map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
            }
            if (value == "Item Category") {
                if (this.itemViewSetup.isCategory) {
                    this.itemViewSetupList.push({
                        id: '00000000-0000-0000-0000-000000000000',
                        name: value,
                        displayName: 'Shoes Category',
                        sequence: 8
                    });
                    this.itemViewSetupList = this.itemViewSetupList.map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
                else {
                    this.itemViewSetupList = this.itemViewSetupList.filter(item => item.name !== value).map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
            }
            if (value == "Item Barcode") {
                if (this.itemViewSetup.isBarcode) {
                    this.itemViewSetupList.push({
                        id: '00000000-0000-0000-0000-000000000000',
                        name: value,
                        displayName: '546512612391',
                        sequence: 8
                    });
                    this.itemViewSetupList = this.itemViewSetupList.map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
                else {
                    this.itemViewSetupList = this.itemViewSetupList.filter(item => item.name !== value).map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
            }
            if (value == "Item Style/Model") {
                if (this.itemViewSetup.isStyle) {
                    this.itemViewSetupList.push({
                        id: '00000000-0000-0000-0000-000000000000',
                        name: value,
                        displayName: 'Slipers',
                        sequence: 8
                    });
                    this.itemViewSetupList = this.itemViewSetupList.map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
                else {
                    this.itemViewSetupList = this.itemViewSetupList.filter(item => item.name !== value).map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
            }
            this.updateDisplayName();

        },
        GetItemDisplayValueForPrint: function (value) {
            debugger;

            if (value == "Item Code") {
                if (this.itemViewSetupPrint.isCode) {
                    this.itemViewSetupListForPrint.push({
                        id: '00000000-0000-0000-0000-000000000000',
                        name: value,
                        displayName: 'PR-00001',
                        sequence: 8
                    });
                    this.itemViewSetupListForPrint = this.itemViewSetupListForPrint.map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
                else {
                    this.itemViewSetupListForPrint = this.itemViewSetupListForPrint.filter(item => item.name !== value).map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
            }
            if (value == "Item Name (English)") {
                if (this.itemViewSetupPrint.isNameEn) {
                   
                    this.itemViewSetupListForPrint.push({
                        id: '00000000-0000-0000-0000-000000000000',
                        name: value,
                        displayName: 'Shoes',
                        sequence: 8
                    });
                    this.itemViewSetupListForPrint = this.itemViewSetupListForPrint.map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
                else {
                    this.itemViewSetupListForPrint = this.itemViewSetupListForPrint.filter(item => item.name !== value).map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
            }
            if (value == "Item Name (Arabic)") {
                if (this.itemViewSetupPrint.isNameAr) {
                    this.itemViewSetupListForPrint.push({
                        id: '00000000-0000-0000-0000-000000000000',
                        name: value,
                        displayName: 'أحذية',
                        sequence: 8
                    });
                    this.itemViewSetupListForPrint = this.itemViewSetupListForPrint.map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
                else {
                    this.itemViewSetupListForPrint = this.itemViewSetupListForPrint.filter(item => item.name !== value).map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
            }
            if (value == "Item Description") {
                if (this.itemViewSetupPrint.isDescription) {
                    this.itemViewSetupListForPrint.push({
                        id: '00000000-0000-0000-0000-000000000000',
                        name: value,
                        displayName: 'These are Shoes',
                        sequence: 8
                    });
                    this.itemViewSetupListForPrint = this.itemViewSetupListForPrint.map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
                else {
                    this.itemViewSetupListForPrint = this.itemViewSetupListForPrint.filter(item => item.name !== value).map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
            }
            if (value == "Item Category") {
                if (this.itemViewSetupPrint.isCategory) {
                    this.itemViewSetupListForPrint.push({
                        id: '00000000-0000-0000-0000-000000000000',
                        name: value,
                        displayName: 'Shoes Category',
                        sequence: 8
                    });
                    this.itemViewSetupListForPrint = this.itemViewSetupListForPrint.map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
                else {
                    this.itemViewSetupListForPrint = this.itemViewSetupListForPrint.filter(item => item.name !== value).map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
            }
            if (value == "Item Barcode") {
                if (this.itemViewSetupPrint.isBarcode) {
                    this.itemViewSetupListForPrint.push({
                        id: '00000000-0000-0000-0000-000000000000',
                        name: value,
                        displayName: '546512612391',
                        sequence: 8
                    });
                    this.itemViewSetupListForPrint = this.itemViewSetupListForPrint.map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
                else {
                    this.itemViewSetupListForPrint = this.itemViewSetupListForPrint.filter(item => item.name !== value).map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
            }
            if (value == "Item Style/Model") {
                if (this.itemViewSetupPrint.isStyle) {
                    
                    this.itemViewSetupListForPrint.push({
                        id: '00000000-0000-0000-0000-000000000000',
                        name: value,
                        displayName: 'Slipers',
                        sequence: 8
                    });
                    this.itemViewSetupListForPrint = this.itemViewSetupListForPrint.map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
                else {
                    this.itemViewSetupListForPrint = this.itemViewSetupListForPrint.filter(item => item.name !== value).map((item, index) => {
                        item.sequence = index + 1;
                        return item;
                    });
                }
            }
            this.updateDisplayName();

        },
        updateDisplayName() {
            // Create a new array to store names in the correct sequence
            const newDisplayName = this.itemViewSetupList
                .sort((a, b) => a.sequence - b.sequence)
                .map(item => item.displayName);

            // Update the displayName
            this.displayName = newDisplayName.join('  ');

            const newDisplayNameForPrint = this.itemViewSetupListForPrint
                .sort((a, b) => a.sequence - b.sequence)
                .map(item => item.displayName);

            // Update the displayName
            this.displayNameForPrint = newDisplayNameForPrint.join('  ');
        },
        SaveItemViewSetup: function () 
        {
            this.loading = true;
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            // this.logistics.branchId = localStorage.getItem('BranchId');

            this.itemViews.itemViewSetupList = [];
            this.itemViewSetupList.forEach(x => {
                this.itemViews.itemViewSetupList.push(x);
            })

            this.itemViews.itemViewSetupListForPrint = [];
            this.itemViewSetupListForPrint.forEach(x => {
                this.itemViews.itemViewSetupListForPrint.push(x);
            })

            this.$https.post('/Product/SaveItemView?isForAllProduct=' + root.isForAllProduct, this.itemViews, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                if (response.data.isSuccess ) {
                    root.$swal({
                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : '!تم الحفظ',
                        text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Data has been Saved' : 'حفظ بنجاح',
                        type: 'success',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        confirmButtonClass: "btn btn-success",
                        buttonsStyling: false
                    })
                    root.GetItemViewSetup();
                }
                else{
                    root.$swal({
                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                        text: response.data.isAddUpdate,
                        type: 'error',
                        confirmButtonClass: "btn btn-info",
                        buttonsStyling: false
                    });
                }
                root.loading = false;
            }
            ).catch(() => {
                root.$swal({
                    title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                    text: "Please Contact with Support.",
                    type: 'error',
                    confirmButtonClass: "btn btn-info",
                    buttonsStyling: false
                });
                root.loading = false;
            });
        },
        GetItemViewSetup: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }


            root.$https.get('/Product/GetItemsViewSetup', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {

                    root.itemViewSetupList = [];
                    root.itemViewSetupListForPrint = [];

                    root.itemViewSetupList =  response.data.itemViewSetupList;
                    root.itemViewSetupListForPrint = response.data.itemViewSetupListForPrint;

                    root.itemViewSetupList.forEach(x => {
                        if(x.name == 'Item Code')
                        {
                            root.itemViewSetup.isCode = true;
                        }
                        else if(x.name == 'Item Name (English)')
                        {
                            root.itemViewSetup.isNameEn = true;
                        }
                        else if(x.name == 'Item Name (Arabic)')
                        {
                            root.itemViewSetup.isNameAr = true;
                        }
                        else if(x.name == 'Item Description')
                        {
                            root.itemViewSetup.isDescription = true;
                        }
                        else if(x.name == 'Item Category')
                        {
                            root.itemViewSetup.isCategory = true;
                        }
                        else if(x.name == 'Item Barcode')
                        {
                            root.itemViewSetup.isBarcode = true;
                        }
                        else if(x.name == 'Item Style/Model')
                        {
                            root.itemViewSetup.isStyle = true;
                        }
                    });
                    root.itemViewSetupListForPrint.forEach(x => {
                        if(x.name == 'Item Code')
                        {
                            root.itemViewSetupPrint.isCode = true;
                        }
                        else if(x.name == 'Item Name (English)')
                        {
                            root.itemViewSetupPrint.isNameEn = true;
                        }
                        else if(x.name == 'Item Name (Arabic)')
                        {
                            root.itemViewSetupPrint.isNameAr = true;
                        }
                        else if(x.name == 'Item Description')
                        {
                            root.itemViewSetupPrint.isDescription = true;
                        }
                        else if(x.name == 'Item Category')
                        {
                            root.itemViewSetupPrint.isCategory = true;
                        }
                        else if(x.name == 'Item Barcode')
                        {
                            root.itemViewSetupPrint.isBarcode = true;
                        }
                        else if(x.name == 'Item Style/Model')
                        {
                            root.itemViewSetupPrint.isStyle = true;
                        }
                    });
                    root.updateDisplayName();
                    root.isForAllProduct = false;
                }
            });

        },
        // UpdateProductsDisplayName: function () {
        //     this.loading = true;
        //     var root = this;
        //     var token = '';
        //     if (this.$session.exists()) {
        //         token = localStorage.getItem('token');
        //     }

        //     root.$https.get('/Product/UpdateProductsDisplayName', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
        //         if (response.data.isSuccess) 
        //         {
        //             root.$swal({
        //                 title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : '!تم الحفظ',
        //                 text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Data has been Saved' : 'حفظ بنجاح',
        //                 type: 'success',
        //                 icon: 'success',
        //                 showConfirmButton: false,
        //                 timer: 2000,
        //                 timerProgressBar: true,
        //                 confirmButtonClass: "btn btn-success",
        //                 buttonsStyling: false
        //             })
        //         }
        //         else
        //         {
        //             root.$swal({
        //                 title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
        //                 text: response.data.isAddUpdate,
        //                 type: 'error',
        //                 confirmButtonClass: "btn btn-info",
        //                 buttonsStyling: false
        //             });
        //         }
        //         root.loading = false;
        //     }).catch(() => {
        //         root.$swal({
        //             title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
        //             text: "Please Contact with Support.",
        //             type: 'error',
        //             confirmButtonClass: "btn btn-info",
        //             buttonsStyling: false
        //         });
        //         root.loading = false;
        //     });
        // },
    },
    created: function () 
    { 
        this.GetItemViewSetup();
    },

    mounted: function () { },
}
</script>

<style>
.circle-singleline {
    margin: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 30px;
    text-align: center;
    background: blue;
    color: #fff;
    vertical-align: middle;
    line-height: 60px;
}</style>