<template>
    <div class="row" v-if="isValid('CanAddAutoTemplate')|| isValid('CanEditAutoTemplate')">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 v-if="purchase.id === '00000000-0000-0000-0000-000000000000'" class="page-title">
                                    {{ $t('AddAutoPurchaseTemplate.AddAutoPurchaseTemplate') }}
                                </h4>
                                <h4 v-else class="page-title">{{ $t('AddAutoPurchaseTemplate.AddAutoPurchaseTemplate') }}</h4>
                            </div>
                            <div class="col-auto align-self-center">
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                   class="btn btn-sm btn-outline-danger">
                                    {{ $t('Sale.Close') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="hr-dashed hr-menu mt-0" />
            <div class="row">
                <div class="col-lg-6">
                    <div class="row form-group" v-bind:key="supplierRender">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('AddAutoPurchaseTemplate.Supplier') }} : <span class="text-danger">*</span>
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8" :key="supplierRender">
                            <supplierdropdown v-model="$v.purchase.supplierId.$model"
                                              :disable="purchase.approvalStatus === 5 && purchase.id != '00000000-0000-0000-0000-000000000000'" v-bind:values="purchase.supplierId" :status="purchase.isRaw" />
                            <!--<a href="javascript:void(0);" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                               aria-controls="offcanvasRight" class="text-primary">View Supplier Details</a>-->
                            <!--<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight"
                                 aria-labelledby="offcanvasRightLabel">
                                <div class="offcanvas-header">
                                    <h5 id="offcanvasRightLabel" class="m-0">Supplier Details</h5>
                                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                                            aria-label="Close"></button>
                                </div>
                                <div class="offcanvas-body">
                                    <div class="row">-->
                                        <!-- <div class="col-lg-12 form-group">
                                    <label>{{ $t('AddSaleOrder.Mobile') }}  :</label>
                                    <input type="text" class="form-control" v-model="purchase.mobile" />
                                </div>
                                <div class="col-lg-12 form-group">
                                    <label>{{ $t('CustomerAddress') }}  :</label>
                                    <textarea rows="3" v-model="purchase.customerAddress" class="form-control"> </textarea>
                                </div> -->
                                    <!--</div>
                                </div>
                            </div>-->
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span id="ember695" class="tooltip-container text-dashed-underline "> {{ $t('AddAutoPurchaseTemplate.RegistrationNo') }} <span class="text-danger">*</span></span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <input v-model="purchase.registrationNo" disabled class="form-control" type="text">
                        </div>
                    </div>

                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline "> {{ $t('AddAutoPurchaseTemplate.Date') }}  <span class="text-danger">*</span></span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <datepicker v-model="purchase.date" />
                        </div>
                    </div>


                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">{{ $t('AddAutoPurchaseTemplate.StartDate') }}:</span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <datepicker v-model="purchase.invoiceDate" />
                        </div>
                    </div>
                    <div class="row form-group" v-if="raw == 'true'">
                        <label class="col-form-label col-lg-4">
                        </label>
                        <div class="inline-fields col-lg-8">
                            <div class="checkbox form-check-inline mx-2">
                                <input type="checkbox" id="inlineCheckbox1" v-model="purchase.isRaw"
                                       @change="ChangeSupplier">
                                <label for="inlineCheckbox1"> {{ $t('AddPurchase.RawProduct') }} </label>
                            </div>

                        </div>
                    </div>
                    <!--<div class="row form-group"
                 v-if="isValid('CreditPurchase') && (isValid('CanViewPostOrder') || isValid('CanAddPurchaseOrder'))">
                <label class="col-form-label col-lg-4">
                    <span class="tooltip-container text-dashed-underline ">
                        {{ $t('AddPurchase.PurchaseOrder') }} :
                    </span>
                </label>
                <div class="inline-fields col-lg-8">
                    <purchase-order-dropdown v-on:input="GetPoData(purchase.purchaseOrderId, false)"
                                             v-model="purchase.purchaseOrderId" v-bind:values="purchase.purchaseOrderId" />
                </div>
            </div>-->
                    <!--<div class="row form-group" v-if="!purchase.isCredit">
                <label class="col-form-label col-lg-4">
                    <span class="tooltip-container text-dashed-underline ">
                        {{ $t('AddPurchase.PaymentType') }} :<span class="text-danger"> *</span>
                    </span>
                </label>
                <div class="inline-fields col-lg-8">
                    <multiselect :options="paymentTypeOptions" @select="GetAccount()"
                                 v-model="purchase.paymentType" :show-labels="false">
                    </multiselect>
                </div>
            </div>-->

                </div>
                <div class="col-lg-6">
                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('AddAutoPurchaseTemplate.ReOrderDay') }}
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <input class="form-control" v-model="purchase.day" />
                        </div>
                    </div>

                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('AddPurchase.WareHouse') }} :<span class="text-danger">
                                    *
                                </span>
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <warehouse-dropdown v-model="$v.purchase.wareHouseId.$model" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('AddPurchase.TaxMethod') }} :<span class="text-danger"> *</span>
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <multiselect :options="options" v-bind:disabled="purchase.purchaseOrderItems.length>0" v-model="purchase.taxMethod" :show-labels="false" v-bind:placeholder="$t('SelectMethod')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            </multiselect>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('AddAutoPurchaseTemplate.VAT') }} :<span class="text-danger"> *</span>
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <taxratedropdown v-model="purchase.taxRateId" :isDisable="purchase.purchaseOrderItems.length>0? true :false" />
                        </div>
                    </div>


                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                        </label>
                        <div class="inline-fields col-lg-8">
                            <div class="checkbox form-check-inline mx-2">
                                <input type="checkbox" id="inlineCheckbox1" v-model="purchase.isActive"
                                       @change="ChangeSupplier">
                                <label for="inlineCheckbox1"> {{ $t('AddAutoPurchaseTemplate.Status') }} </label>
                            </div>

                        </div>
                    </div>
                </div>

                <auto-purchase-template-item @input="SavePurchaseItems" ref="childComponentRef" :taxMethod="purchase.taxMethod" :taxRateId="purchase.taxRateId" :raw="purchase.isRaw" :key="rander" />


                <div class="col-lg-12 invoice-btn-fixed-bottom">
                    <div v-if="!loading && purchase.id == '00000000-0000-0000-0000-000000000000'"
                         class="col-md-12 arabicLanguage">

                        <button class="btn btn-outline-primary me-2"
                                v-on:click="savePurchase()"
                                v-if="isValid('CanAddAutoTemplate')"
                                v-bind:disabled="$v.$invalid || purchase.purchaseOrderItems.filter(x => x.totalPiece=='').length > 0 || purchase.purchaseOrderItems.filter(x => x.unitPrice=='').length > 0">
                            <i class="far fa-save"></i> {{ $t('AddAutoPurchaseTemplate.SaveAsPost') }}
                        </button>


                        <button class="btn btn-danger me-2" v-on:click="goToPurchase">
                            {{ $t('AddAutoPurchaseTemplate.Cancel') }}
                        </button>
                    </div>
                    <div v-if="!loading && purchase.id != '00000000-0000-0000-0000-000000000000'"
                         class="col-md-12 arabicLanguage">

                        <button class="btn btn-outline-primary me-2"
                                v-on:click="savePurchase()"
                                v-if="isValid('CanEditAutoTemplate')"
                                v-bind:disabled="$v.$invalid || purchase.purchaseOrderItems.filter(x => x.totalPiece=='').length > 0 || purchase.purchaseOrderItems.filter(x => x.unitPrice=='').length > 0">
                            <i class="far fa-save"></i> {{ $t('AddAutoPurchaseTemplate.UpdateAsPost') }}
                        </button>

                        <button class="btn btn-danger me-2" v-on:click="goToPurchase">
                            {{ $t('AddAutoPurchaseTemplate.Cancel') }}
                        </button>
                    </div>
                </div>

                <div class="col-lg-12 mt-4 mb-5">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-8" >
                                    <!--<label>{{ $t('AddPurchaseOrder.TermandCondition') }}:</label>
                                    <textarea class="form-control " rows="3" v-model="purchase.note" />-->
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group ps-3" v-if="!loading">

                                        <div class="font-xs mb-1">{{ $t('AddAutoPurchaseTemplate.AttachFiles') }}</div>

                                        <button v-on:click="Attachment()" type="button"
                                                class="btn btn-light btn-square btn-outline-dashed mb-1">
                                            <i class="fas fa-cloud-upload-alt"></i> {{ $t('AddPurchase.Attachment') }}
                                        </button>

                                        <div>
                                            <small class="text-muted">
                                                {{ $t('AddAutoPurchaseTemplate.FileSize') }}
                                            </small>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <bulk-attachment :attachmentList="productDetail.attachmentList" :show="show" v-if="show" @close="attachmentSave" />

            <loading :active.sync="loading" :can-cancel="true" :is-full-page="true"></loading>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from "vue-loading-overlay";
    import "vue-loading-overlay/dist/vue-loading.css";
    import moment from "moment";
    import { required } from "vuelidate/lib/validators";
    import Multiselect from 'vue-multiselect'
  

    //import VueBarcode from 'vue-barcode';
    export default {
        mixins: [clickMixin],
        components: {
            Multiselect,
            Loading,

        },
        data: function () {
            return {

                currency: '',
                totalAmount: 0,
                daterander: 0,
                rander: 0,
                purchase: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    registrationNo: "",
                    supplierId: "",
                    day: "",
                    taxMethod: "",
                    taxRateId: "",
                    wareHouseId: "",
                    note: '',
                    branchId: '',
                    purchaseOrderItems: [],
                    isRaw: false,
                    isActive: true,
                },
                raw: '',
                loading: false,
                language: 'Nothing',
                options: [],
                supplierRender: 0,
                show: false,
            };
        },
        validations: {
            purchase: {
                date: { required },
                registrationNo: { required },
                supplierId: { required },
                day: { required },
                purchaseOrderItems: { required },
                wareHouseId: { required },
            },
        },
        methods: {
            attachmentSave: function (attachment) {
                
                this.purchase.attachmentList = attachment;
                this.show = false;
            },
            Attachment: function () {
                this.show = true;
            },
            getTotalAmount: function () {
                this.totalAmount = this.$refs.childComponentRef.getTotalAmount();
            },
            getDate: function (date) {
                if (date == null || date == undefined) {
                    return "";
                }
                else {
                    return moment(date).format('LLL');
                }
            },
            ChangeSupplier: function () {
                this.supplierRender++;
                this.rander++;
            },
            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.purchase.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addproduct');
                    }
                    else {
                        this.$swal({
                            title: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text:  (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'You cannot Change language during update, otherwise your current page data will be lose!' : 'لا يمكنك تغيير اللغة أثناء التحديث ، وإلا ستفقد بيانات صفحتك الحالية!',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },

            GotoPage: function (link) {
                this.$router.push({ path: link });
            },
            AutoIncrementCode: function () {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/Purchase/AutoPurchaseAutoGenerateNo?branchId=" + localStorage.getItem('BranchId'), {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.purchase.registrationNo = response.data;
                        }
                    });
            },
            SavePurchaseItems: function (purchaseOrderItems) {
                this.purchase.purchaseOrderItems = purchaseOrderItems;
                this.getTotalAmount();
            },

            savePurchase: function () {
                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.purchase.branchId = localStorage.getItem('BranchId');

                this.purchase.isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
                //root.purchase.date = root.purchase.date + " " + moment().format("hh:mm A");
                this.$https.post('/Purchase/SaveAutoPurchaseTemplate', root.purchase, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                            root.info = response.data.bpi
                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : '!تم الحفظ',
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.go('AddAutoPurchaseTemplate');
                                }
                            });
                        }
                        else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                            root.info = response.data.bpi
                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : '!تم الحفظ',
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.push({
                                        path: '/AutoPurchaseTemplate',
                                        query: {
                                            data: 'AutoPurchaseTemplate'
                                        }
                                    })
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                type: 'error',
                                icon: 'error',
                                title: root.$t('Error'),
                                text: error.response.data,
                                confirmButtonClass: "btn btn-danger",
                                showConfirmButton: true,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)

            },
            goToPurchase: function () {
                
                this.$router.push({
                    path: '/AutoPurchaseTemplate',
                    query: {
                        data: 'AutoPurchaseTemplate'
                    }
                })
            },
            ViewPaymentVoucher: function (id, expense) {
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Purchase/PurchaseOrderPaymentDetail?Id=' + id + '&expense=' + expense, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$https.get('/PaymentVoucher/PaymentVoucherDetails?Id=' + response.data.paymentVoucherId, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                            if (response.data != null) {
                                root.paymentview = response.data;
                                if (expense) {
                                    root.isExpenseview = true;
                                }
                                else {
                                    root.isPaymentview = true;
                                }
                            }
                        });
                    }
                });
            },
            paymentView: function () {
                this.isPaymentview = false;
                this.isExpenseview = false;
            }
        },
        created: function () {
           this.defaultVat = localStorage.getItem('DefaultVat');
            this.purchase.date = moment().format("LLL");

            this.$emit('input', this.$route.name);

            if (this.$route.query.data != undefined) {
                this.purchase = this.$route.query.data;
                this.purchase.date = moment(this.purchase.date).format("LLL");

                this.rander++;
                this.rendered++;
            }
            else {

                this.purchase.invoiceDate = moment().isoWeekday(7);
            }
        },
        mounted: function () {
            this.language = this.$i18n.locale;
            this.currency = localStorage.getItem('currency');

            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.options = ['Inclusive', 'Exclusive'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
            }
            if (this.$session.exists()) {
                this.raw = localStorage.getItem('IsProduction');
            }
            if (this.$route.query.data == undefined) {
                this.AutoIncrementCode();

                this.daterander++;
            }
        },
    };
</script>
<style scoped>
    .poHeading {
        font-size: 32px;
        font-style: normal;
        line-height: 37px;
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        color: #3178F6;
        text-align: center
    }

    .dateHeading {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.01em;
        text-align: left;
        color: #35353D;
    }

    .bottomBorder {
        padding-top: 24px !important;
        border-bottom: 1px solid #EFF4F7;
    }
</style>
