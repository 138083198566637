<template>
    <div class="dropdown-menu dropdown-menu-end" v-if="islayout">
        <a v-for="(item,index) in options" v-bind:key="index" @click="selectedValue(item)" class="dropdown-item" href="javascript:void(0)">
            {{item.name}}
        </a>
    </div>

    <div v-else>
        <multiselect v-model="DisplayValue" :options="options" :disabled="disabled" :multiple="ismultiple" v-bind:placeholder="$t('Branches.SelectBranch')" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true">
            <p slot="noResult" class="text-danger"> Oops! No Branch found.</p>
        </multiselect>

        <addbranches :terminal="newBranch"
                     :show="show"
                     v-if="show"
                     @close="close()"
                     :type="type" />
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import clickMixin from '@/Mixins/clickMixin'
    
    export default {
        mixins: [clickMixin],
        name: 'branchdropdown',
        props: ["values", 'disabled', 'islayout' , 'ismultiple', 'fromBranch','selectAll'],

        components: {
            Multiselect
        },
        data: function () {
            return {
                arabic: '',
                english: '',
                options: [],
                value: '',
                show: false,
                type: '',
                newBranch: {},
                render: 0,
                loading: false,
            }
        },
        methods: {
            Remove: function () {
            this.value='';

        },
            openmodel: function () {
                this.newBranch = {
                    id: '00000000-0000-0000-0000-000000000000',
                    code: '',
                    branchName: '',
                    contactNo: '',
                    address: '',
                    city: '',
                    state: '',
                    postalCode: '',
                    country: '',
                }

                this.show = !this.show;
                this.type = "Add";
            },
            getData: function () {
                
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.islayout = this.islayout == true ? true : false;
                this.$https.get('/Branches/BranchList?isDropdown=true' + '&isLayout=' + this.islayout + '&locationId=' + localStorage.getItem('CompanyID'), { headers: {"Authorization": `Bearer ${token}`}
                }).then(function (response) {
                    if (response.data != null) {
                        response.data.results.forEach(function (result) {
                            if (root.fromBranch != undefined && root.fromBranch != "") {
                                if (root.fromBranch != result.id) {
                                    root.options.push({
                                        id: result.id,
                                        mainBranch: result.mainBranch,
                                        name: result.code + ' ' + result.branchName,
                                    })
                                }
                            }
                            else
                            {
                                root.options.push({
                                    id: result.id,
                                    mainBranch: result.mainBranch,
                                    name: result.code + ' ' + result.branchName,
                                })
                            }
                        })
                    }
                }).then(function () {
                    if (root.ismultiple == true) {
                        if (root.values != undefined && root.values != '' && root.values != null) {
                            root.value = root.values;
                        }
                    }
                    else {
                        root.value = root.options.find(function (x) {
                            return x.id == root.values;
                        })
                    }
                    
                });
            },

            close: function () {
                this.show = false;
                this.getData();
            },

            selectedValue: function (value) {
                this.$emit('input', value);
            },
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {
                    this.value = value;
                    if (this.ismultiple) {
                        this.$emit('input', value);
                    }
                    else {
                        this.$emit('input', value.id);
                    }                    
                }
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.getData();
        },
    }
</script>
